import { Banner } from '@storefront/ui-components/shared/banner';
import { Hero } from '@storefront/ui-components/shared/hero';
import { ProductFilter } from '@storefront/ui-components/shared/product-filter';
import { type ReactNode } from 'react';

interface NavbarContentProps {
  heroTitle?: string;
  subTitle?: ReactNode;
  bannerCopy?: string;
  filters?: {
    label: string;
    icon: JSX.Element;
    href: string;
  }[];
  hasHero?: boolean;
  hasBanner?: boolean;
  hasProductFilter?: boolean;
}

const defaultHeroTitle = 'Welcome To Descend';
const defaultSubTitle = (
  <div>
    The descending price auction <span className="whitespace-nowrap">featuring Ashley furniture</span>
  </div>
);
const defaultBannerCopy =
  'At Descend, prices actually drop over time. Be the first shopper to buy when you like the price and the item is yours!';

const NAV_FILTERS = [
  {
    label: 'All Products',
    icon: <img className="icon" src="/assets/icons/all.svg" alt="All Icon" />,
    href: '/collections/products',
  },
  {
    label: 'Living Room',
    icon: <img className="icon" src="/assets/icons/couch.svg" alt="Living Room Icon" />,
    href: '/collections/living-room',
  },
  {
    label: 'Dining Room',
    icon: <img className="icon" src="/assets/icons/dining.svg" alt="Dining Room Icon" />,
    href: '/collections/dining-room',
  },
  {
    label: 'Bedroom',
    icon: <img className="icon" src="/assets/icons/bedroom.svg" alt="Bedroom Icon" />,
    href: '/collections/bedroom',
  },
  {
    label: 'Mattress',
    icon: <img className="icon" src="/assets/icons/mattress.svg" alt="Mattress Icon" />,
    href: '/collections/mattress',
  },
];

export const NavbarContent = ({
  heroTitle = defaultHeroTitle,
  subTitle = defaultSubTitle,
  bannerCopy = defaultBannerCopy,
  filters = NAV_FILTERS,
  hasHero = true,
  hasBanner = true,
  hasProductFilter = true,
}: NavbarContentProps) => {
  return (
    <>
      {hasHero && <Hero title={heroTitle} subtitle={subTitle} />}

      {hasBanner && <Banner copy={bannerCopy} />}

      {hasProductFilter && <ProductFilter filters={filters} />}
    </>
  );
};
