import { Link } from '@remix-run/react';
import type { RemixLinkProps } from '@remix-run/react/dist/components';
import { type HTMLAttributes, type FC } from 'react';

export interface BannerProps extends HTMLAttributes<HTMLElement> {
  copy: string;
  callToAction?: { label: string } & RemixLinkProps;
}

export const Banner: FC<BannerProps> = ({ className = '', ...props }) => {
  const { copy, callToAction } = props;
  const { label, to, ...ctaProps } = callToAction || {};

  return (
    <div className={`flex justify-center w-full px-4 py-3 border border-gray-200 bg-gray-100 ${className}`}>
      <div className="items-center md:flex">
        <p className="text-center text-sm sm:text-base text-gray-600">
          {copy}
          {label && to ? (
            <Link
              to={to}
              className="inline-flex items-center ml-2 italic hover:underline hover:text-gray-900"
              {...ctaProps}
            >
              {label}
            </Link>
          ) : null}
        </p>
      </div>
    </div>
  );
};
