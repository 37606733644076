import { useImageProxySrc } from '@storefront/util/img-proxy';
import { type FC, type HTMLAttributes, type ReactNode } from 'react';

export interface HeroProps extends HTMLAttributes<HTMLElement> {
  title: string;
  subtitle: ReactNode;
  backgroundImage?: string;
  backgroundColor?: string;
}

export const Hero: FC<HeroProps> = ({ className: _className, ...props }) => {
  const { title, subtitle, backgroundImage, backgroundColor, ...rest } = props;

  const imageSrc = useImageProxySrc('/assets/images/hero-ashley.png?url', { context: 'large_square' });

  return (
    <section
      className="flex h-[214px] items-center justify-center bg-white bg-center bg-no-repeat align-middle dark:bg-gray-900 bg-cover"
      {...rest}
      style={{
        backgroundImage: backgroundImage ?? `url(${imageSrc as string})`,
        backgroundColor: backgroundColor ?? 'rgba(248, 140, 41, 0.50)',
      }}
    >
      <div className="text-center">
        <h1 className="mb-4 text-2xl font-bold tracking-wide text-white sm:text-5xl uppercase sm:mb-1">{title}</h1>
        <h2 className="text-base font-semibold leading-normal text-white md:text-2xl">{subtitle}</h2>
      </div>
    </section>
  );
};
